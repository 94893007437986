#brand_tob,
.brand_tob {
  display: none !important;

  @include media-breakpoint-down(lg) {
    display: flex !important;
    height: 76px;
  }

  .logoabove_tab {
    img {
      height: 64px;
      width: 102px;

    }
  }

  .menu-icon {
    margin-left: 10px;
  }

  .search-icon {
    margin-right: 10px;
  }

  .btn {
    background: #fff;

    i {
      font-size: 30px;
      color: #616161;
    }
  }
}

#slide-out {
  .slide-outleftmeun {
    height: 68px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    .menu-icons{
      background: transparent;
      margin-right: 17%;
    }
    .icon-arrow-left {
      font-size: 20px;
    }

    .logoabove_left {
      img {
        width: 68px;
        ;
      }
    }
  }
}

.side-nav {
  @include media-breakpoint-down(lg) {
    z-index: 10023;
  }
}

.listnavbarabove {
  margin-top: 24px;

  .search-input {
    margin: 0 24px;

    .form-control {
      height: 32px;
      width: 100%;
      font-size: 15px;
      padding-left: 10px;

      
    }
    .search-icons{
      position: absolute;
      top: 0px;
      right: 0;
      background: #49AF59;
      height: 32px;
      width: 32px;
      .icon-search{
        top: 15%;
        right: 0;
        font-size: 20px;
      }
    }
  }
}
#header_tab_nav{
  list-style: none;
  padding-left: 12px;
  margin-right: 10px;
  li{
    margin-top: 32px;
    h4{
      margin: 0;
      
      a{
        font-size: 18px;
        color: #313131;
        border-bottom: 1px solid transparent;
        padding: 0;
        &:hover{
          // border-bottom: 1px solid #49AF59;
        color: #49AF59;
        }
      }
      .li_list_tab_active{
        border-bottom: 1px solid #49AF59;
        color: #49AF59;
      }
    }
    .ul_tab_list{
      list-style: none;
      padding-left: 24px;
      .li_tab_list{
        margin: 12px 0;
        a{
          padding: 0;
          line-height: 1.3;
        }
      }
    }
    
  }
}
