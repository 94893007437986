#Godpromiseheader {
  #headerbg {
    height: 12px;
    background: #49AF59;
    width: 100%;

    @include media-breakpoint-down(lg) {
      height: 4px;
    }
  }

  .inner-header {
    height: 90px;
    width: 100%;

    @include media-breakpoint-down(lg) {
      display: none;
      height: 64px;
    }

    .container {
      position: relative;
      height: 100%;
    }
  }

  .headerbot {
    height: 2px;
    background: #49AF59;
    z-index: 9;
  }

}

.brand,
.brandabove {
  height: 100%;

  .logo {
    img {
      height: 76px;
      width: auto;
    }
  }

  #navbar {
    margin-left: 1%;
    height: 100%;
    margin: auto;

    .menu-admin-nav {
      height: 100%;

      .menu-item {
        margin: 0 12px;
        font-weight: 700;
        font-size: 16px;
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 4px solid transparent;
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;

        &:hover {
          border-bottom: 4px solid #37c643;

          a {
            color: #37c643;
            text-decoration: none;
          }

        }
      }

      .liactive {
        border-bottom: 4px solid #37c643;

        a {
          color: #37c643;
          text-decoration: none;
        }
      }
    }
    
  }

  .icon-search {
    font-size: 37px;
    color: #49AF59;
    margin-left: 3%;

    &:hover {
      background: #E5E6EB;
    }
  }

  .logoabove {
    img {
      height: 64px;
      width: 102px;
    }
  }

  #navbarabove,
  .listnavbarabove {
    margin-left: 5%;
    align-items: center;
    display: flex;
  }

  .icon-close {
    font-size: 32px;
    margin-left: 24px;
    color: #49AF59;
  }
}

.search-input {
  position: relative;

  .form-control {
    width: 948px;
    height: 52px;
    padding: .5rem 1.5rem;
    font-size: 18px;
    color: #919191;
  }

  .search-icon {
    position: absolute;
    top: 0px;
    right: 0;
    background: #49AF59;
    width: 52px;
    height: 52px;

    .icon-search {
      position: absolute;
      top: 25%;
      right: 19%;
      color: #fff;
      cursor: pointer;
      font-size: 25px;

      &:hover {
        background: #49AF59;
      }
    }
  }
}

#brand,
#stick-nav {
  height: 100%;

  .container,
  .stick_navbar_box {
    height: 100%;
  }
}

.brandabove {
  position: absolute;
  top: 122px;
  background: #fff;
  width: 100%;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.dropdown {
  &:hover {
    .down-menu {
      opacity: 1;
      top: 105%;
      visibility: visible;
      border-radius: 5px;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, .15);
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);

    }
  }

  .down-menu {
    list-style: none;
    border-radius: 5px;
    // background: #ffffff none repeat scroll 0 0;
    // box-shadow: 0 0 0px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .1);
    left: 0;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    top: 120%;
    transition: all 0.3s;
    visibility: hidden;
    width: 170px;
    z-index: 9999;

    ._secondli {
      font-size: 16px;
      padding: 8px 20px;
      border-bottom: 1px solid #ecf0f5;

      &:nth-last-child(1) {
        border-bottom: none;
      }

      a {
        color: #313131 !important;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        color: #616161;
        font-size: 15px;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        line-height: 1.42857;
        padding: 2px 0;
        font-weight: 400;
      }

      &:hover {
        background: #FAFAFAFA;

        a {
          color: #49AF59 !important;
        }
      }
    }
  }
}

.box {
  width: 100%;
  height: 100%;
  // opacity: 0.5;
  position: absolute;
  top: 0;
  overflow: hidden;
  display: none;
  -webkit-transition: all 1.3s ease;
  -o-transition: all 1.3s ease;
  transition: all 1.3s ease;
}

#stick-nav {
  .logo {
    img {
      height: 64px;
      width: 102px;
    }
  }
}

// ===========new header menu==============
$color-one: #49AF59;
@mixin ls {
  list-style: none;
  padding: 0;
  margin: 0;
}
#brand,
#stick-nav {
  ul {
    @include ls;

    a {
      display: block;
      color: #212121;

      &:hover {
        color: $color-one;
      }
    }

    ul {
      position: absolute;
      top: 100%;
      left: -999em;
      z-index: 99999;

      ul {
        top: 0;
        margin-top: -5px;
        left: -999em;
      }

      li {
        &:hover {
          >ul {
            left: 100%;
            right: auto;
          }
        }
      }
    }

    li {
      &:hover {
        >ul {
          left: 0.5em;
          right: auto;
        }
      }
    }
  }

  li {
    display: inline-block;
    position: relative;
    padding: 8px 16px;
    white-space: nowrap;

    li {
      display: block;
    }
  }

  .sub-menu {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

    .menu-item-has-children {
      >a {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 20px;
          position: relative;
          right: -10px;
        }
      }
    }
    .current-menu-item>a,
    li.current-menu-ancestor>a {
      color: $color-one;
    }
  }

  .p-menu {
    display: flex;

    >li {
      padding-top: 36px;
      padding-bottom: 36px;
      transition: all 0.3s;

      &.current-menu-item,
      &.current-menu-ancestor {
        border-bottom: 4px solid $color-one;
        > a {
          color: $color-one;
        }
      }

      >a {
        font-size: 16px;
        font-weight: 600;
      }

      li {
        border-bottom: 1px solid #eee;
        min-width: 150px;

        &:hover {
          background: #FAFAFAFA;
        }
      }
    }
  }
}
#stick-nav {
  .p-menu {
    >li {
      padding-top: 26px;
      padding-bottom: 22px;
    }
  }
}

// ============= slider menu on mobile==========
#slide-out {
  ul {
    @include ls;

    .icon-next-font {
      display: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 12px;
      margin: 0 -8px;

      li {
        width: 100%;
        margin-top: 17px;
        margin-bottom: 5px;
        padding: 0 8px;
        position: relative;

        ul {
          margin: 0;
        }
      }

      a {
        font-size: 15px;
        color: #616161;
      }
    }
  }

  #header_tab_nav {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 20px;
    >li {
      width: 100%;
      >a {
        font-size: 18px;
        color: #313131;
      }

      >ul {
        margin-left: 12px;
      }
    }
    .current-menu-item>a {
      border-bottom: 1px solid $color-one;
      color: $color-one;
    }

    a {
      padding: 0;
      &:hover {
        color: $color-one;
      }
    }
  }
}
