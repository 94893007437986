#stick-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10010;
  background-color: $white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 80px;
  padding-bottom: 12px;
  border-radius: 0;
  display: none;
  @include media-breakpoint-down(lg) {
    height: auto;
    padding-bottom: 0;
  }
  &.headroom {
    will-change: transform;
    transition: transform 250ms ease-in-out;
  }

  &.headroom--pinned {
    transform: translateY(0%);
  }

  &.headroom--unpinned {
    transform: translateY(-100%);
  }
  .pcremovebg{
    height: 12px;
    background: linear-gradient(to left, rgba(55, 198, 67, 1.0), rgba(55, 198, 67, 1.0), rgba(73,175,89, 1) 100%);
    width: 100%;
  }
}
.stick_navbar_box{
  position: relative;
}
